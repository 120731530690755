// TODO: migrate to pinia
import { PanelOpenState } from "../../utils/enum.js";
import { ProductI } from "@/model/ProductI";
import { getCablesVar, withPatch } from "@/utils/cables";
import { EB } from "@/utils/EB.js";

const state = {
  isProductOverlayVisible: false,
  isPromiseGlobalPresenceOverlayVisible: false,
  global_presense_loaded: false,
  global_presence_group: -1,
  global_presence: [],
  product: null,

  baseOverlayParams: {
    type: null,
    appPanelState: PanelOpenState.CLOSED,
    mainHeaderTitle: "baseHeadline",
    childHeaderTitle: null,
    panelHeight: 60,
    panelHeightResized: 0,
    activeOverlayIndex: 0,
  },
  productOverlayParams: {
    sectionName: null,
    groupId: null,
    productId: null,
  },
  apiDataLoaded: false,
  productOverlayPanelWidth: 275,
  productOverlayFirstTimeOpen: false,
  productResponse: null,
  // wx actions
  productOverlayAction: {
    name: "",
    value: "",
  },
  settingsAction: {
    name: "",
    value: "",
  },
};

// ================== ACTIONS =========================
const actions = {
  hideAllOverlays({ commit }) {
    commit("CLEAR_GLOBAL_PRESENSE");
    commit("CLEAR_VALUES");
    commit("HIDE_PRODUCT_OVERLAY");
  },

  async openProductOverlayFromStateParams({ commit, getters, dispatch }) {
    let _config = {
      type: "PRODUCT",
      sectionName: "",
      groupId: 0,
      productId: 0,
    };

    const isCareer = getters.CURRENT_PATH_ITEMS.includes("career");
    const isEmployees = getters.CURRENT_PATH_ITEMS.includes("employees");
    const isPromiseValuesDetails =
      getters.CURRENT_PATH_ITEMS.includes("promise") &&
      getters.CURRENT_PATH_ITEMS.includes("values") &&
      getters.CURRENT_PATH_ITEMS.includes("detail");

    if (isCareer && isEmployees) {
      const empInfo = getters.CURRENT_PATH_ITEMS[2];
      const parts = empInfo.split("_");
      const groupId = parts[0];
      parts.shift();
      const productId = parts.join("_");
      _config.sectionName = getters.CURRENT_PATH_ITEMS[0];
      _config.groupId = groupId;
      _config.productId = productId;
      _config.delayOpen = 500;
    } else if (isCareer || isPromiseValuesDetails) {
      _config.sectionName = getters.CURRENT_PATH_ITEMS[0];
      _config.groupId = getters.CURRENT_PATH_ITEMS[1];

      if (isPromiseValuesDetails) {
        _config.productId = Number(getters.CURRENT_PATH_ITEMS[3]) + 1;
      } else {
        _config.productId = getters.CURRENT_PATH_ITEMS[2];
      }
      _config.delayOpen = 500;
    } else {
      if (getters.CURRENT_PATH_ITEMS[1].startsWith("knitting_group")) {
        _config.sectionName = "knitting";
        _config.groupId = getters.CURRENT_PATH_ITEMS[1].replace(
          /^knitting_/,
          ""
        );
      } else if (getters.CURRENT_PATH_ITEMS[1].startsWith("felting_products")) {
        _config.sectionName = "felting";
        _config.groupId = getters.CURRENT_PATH_ITEMS[1].replace(
          /^felting_/,
          ""
        );
      } else {
        _config.sectionName = getters.CURRENT_PATH_ITEMS[1];
      }

      // Cables use product sort order value in URL for a product.
      // In order to load product from API, we have to use productId instead.
      // Thus we retrieve productId from the sortOrders map
      const productIndex = parseInt(getters.CURRENT_PATH_ITEMS[2]) + 1;
      const { sectionName, groupId } = _config;

      const orders = await dispatch("fetchAllSortOrders");
      const sortOrders =
        orders[`${sectionName}_${groupId}_sort_orders`] ??
        orders[`${sectionName}_sort_orders`];

      _config.productId = sortOrders[productIndex - 1];
    }
    commit("CLEAR_VALUES");
    commit("OPEN_PRODUCT_OVERLAY", _config);
    if (isCareer) dispatch("fetchProductData", _config);
  },

  async fetchAllSortOrders({ commit, rootGetters, state }) {
    if (!state.sortOrders) {
      // Sort orders are locale independent.
      // Let's use any locale since it's required by API
      const completeURL = rootGetters.apiPath + `complete/en`;
      let data = getCablesVar("lang");
      if (!data) {
        data = await fetch(completeURL)
          .then((resp) => resp.json())
          .then((data) => {
            return data;
          });
      }

      const sortOrders = Object.keys(data)
        .filter((key) => {
          return key.endsWith("sort_orders");
        })
        .reduce((orders, key) => {
          return { ...orders, [key]: data[key] };
        }, {});

      commit("sortOrders", sortOrders);
    }

    return state.sortOrders;
  },

  refreshProductLocale({ commit }, locale) {
    // unfortunatelly we can't use main locale,
    // cayse for some reaon it changes with a 5 secs delay
    commit("REBUILD_PRODUCT", locale);
  },

  async fetchProductData({ commit, getters, rootGetters }) {
    commit("CURRENT_PRODUCT", null);

    const { sectionName, groupId, productId } = getters.productOverlayParams;

    const _url =
      rootGetters.apiPath +
      `section/full/${sectionName}/${groupId}/${productId}`;

    fetch(_url)
      .then((resp) => resp.json())
      .then((data) => {
        commit("CURRENT_PRODUCT", {
          data: data,
          currentLocale: rootGetters.currentLocale,
        });
      });
  },

  async fetchGloabelPresence({ commit, rootGetters }) {
    fetch(
      rootGetters.apiPath +
        "promise/global-presence-modules/" +
        rootGetters.currentLocale
    )
      .then((response) => response.json())
      .then((data) => {
        commit("SET_GLOBAL_PRESENCE", data);
      });
  },

  baseOverlayHeaderTapped({ commit, getters, rootGetters }) {
    if (
      getters.baseOverlayParams.appPanelState == PanelOpenState.CLOSED &&
      parseInt(getters.baseOverlayParams.activeOverlayIndex) == 0
    ) {
      commit("SET_BASE_OL_PARAMS", { appPanelState: PanelOpenState.OPEN });

      setTimeout(() => {
        if (getters.baseOverlayParams.type === "PROMISE_VALUES") {
          var _mData = [];
          for (var i = 0; i < rootGetters.CURRENT_PATH_ITEMS.length; i++) {
            if (i < 2) {
              _mData.push(rootGetters.CURRENT_PATH_ITEMS[i]);
            } else if (
              i == 3 &&
              rootGetters.CURRENT_PATH_ITEMS[2] == "detail"
            ) {
              _mData.push(
                `detail_${parseInt(rootGetters.CURRENT_PATH_ITEMS[3]) + 1}`
              );
              _mData.push("detail");
            }
          }
          commit("matomoTrackEvent", _mData, { root: true });

          commit("SET_BASE_OL_PARAMS", { activeOverlayIndex: 1 });

          withPatch((patch) => {
            patch.config.valuesOverlayOpen();
          });
        }
      }, 50);
    } else if (parseInt(getters.baseOverlayParams.activeOverlayIndex) > 1) {
      setTimeout(() => {
        commit("SET_BASE_OL_PARAMS", { activeOverlayIndex: 1 });
      }, 150);
    }
  },

  baseOverlayToggleButtonTapped({ commit, getters, rootGetters }) {
    if (
      getters.baseOverlayParams.appPanelState == PanelOpenState.CLOSED &&
      parseInt(getters.baseOverlayParams.activeOverlayIndex) == 0
    ) {
      commit("SET_BASE_OL_PARAMS", { appPanelState: PanelOpenState.OPEN });

      setTimeout(() => {
        if (getters.baseOverlayParams.type === "PROMISE_VALUES") {
          // if(getters.baseOverlayParams.type === 'PROMISE_VALUES'){
          var _mData = [];
          for (var i = 0; i < rootGetters.CURRENT_PATH_ITEMS.length; i++) {
            if (i < 2) {
              _mData.push(rootGetters.CURRENT_PATH_ITEMS[i]);
            } else if (
              i == 3 &&
              rootGetters.CURRENT_PATH_ITEMS[2] == "detail"
            ) {
              _mData.push(
                `detail_${parseInt(rootGetters.CURRENT_PATH_ITEMS[3]) + 1}`
              );
              _mData.push("detail");
            }
          }
          commit("matomoTrackEvent", _mData, { root: true });
          //}

          commit("SET_BASE_OL_PARAMS", { activeOverlayIndex: 1 });
          withPatch((patch) => {
            patch.config.valuesOverlayOpen();
          });
        }
      }, 50);
    } else {
      withPatch((patch) => {
        if (
          rootGetters.isMobile &&
          getters.baseOverlayParams.type === "PRODUCT"
        ) {
          patch.config.triggerAudioEvent("open_overlay");
        }
        if (getters.baseOverlayParams.type === "PROMISE_VALUES") {
          patch.config.valuesOverlayClose();
        }
      });

      commit("SET_BASE_OL_PARAMS", { appPanelState: PanelOpenState.CLOSED });
      setTimeout(() => {
        EB.$emit("panelEvent", { type: "CLOSE_PANEL" });
      }, 250);
      setTimeout(() => {
        commit("SET_BASE_OL_PARAMS", {
          activeOverlayIndex: 0,
          childHeaderTitle: null,
        });
      }, 250);
      setTimeout(() => {
        commit("SET_BASE_OL_PARAMS", {
          panelHeightResized: 0,
        });
      }, 350);
    }
  },

  product_sub_menu_selected({ commit, getters }, subOverlayIndex) {
    const title = getters.currentProduct._overlays
      .find((o) => o.id === subOverlayIndex)
      ?._items.find((i) => i.type === "HL");

    if (title) {
      const matomoCategory =
        getters.productOverlayParams.sectionName +
        " - prod_" +
        getters.productOverlayParams.productId;

      const translatedTitle = translate(title.value, getters.currentLocale);
      const matomoAction = `Select Overlay "${translatedTitle}"`;

      commit("matomoTrackEvent", [matomoCategory, matomoAction]);
    }

    setTimeout(() => {
      commit("SET_BASE_OL_PARAMS", {
        activeOverlayIndex: subOverlayIndex.toString(),
      });
    }, 150);
    commit("SET_BASE_OL_PARAMS", {
      childHeaderTitle:
        getters.currentProduct.getHeadlineByKey(subOverlayIndex),
    });
    return;
  },
  changeProductOverlayFirstTimeOpen({ commit }, value) {
    commit("setProductOverlayFirstTimeOpen", value);
  },
};

const mutations = {
  sortOrders(state, value) {
    state.sortOrders = value;
  },

  SET_BASE_OL_PARAMS(state, _values) {
    for (var prop in _values) {
      if (!Object.prototype.hasOwnProperty.call(_values, prop)) continue;
      state.baseOverlayParams = {
        ...state.baseOverlayParams,
        [prop]: _values[prop],
      };
    }
  },

  SET_GLOBAL_PRESENCE_GROUP(state, index) {
    state.global_presence_group = index;
  },

  SET_GLOBAL_PRESENCE(state, value) {
    for (let i = 0; i < value.length; i++) {
      const element = value[i];

      if (state.global_presence[i] != null) {
        state.global_presence[i].content._copy_1 = element.content._copy_1;
        state.global_presence[i].content._hl_1 = element.content._hl_1;
      } else {
        state.global_presence.push({ index: i, content: element.content });
      }
    }
    state.global_presense_loaded = true;
  },
  CLEAR_GLOBAL_PRESENSE(state) {
    state.global_presense_loaded = false;
    state.isPromiseGlobalPresenceOverlayVisible = false;
    state.global_presence = [];
  },

  REBUILD_PRODUCT(state, locale) {
    const response = state.productResponse;
    if (response) {
      const data = {
        ...response,
        overlays: response.overlays.map(normalizeOverlay(locale)),
      };

      state.product = new ProductI(data, locale);
    } else {
      state.product = null;
      state.apiDataLoaded = false;
    }
  },

  CURRENT_PRODUCT(state, value) {
    if (!value) {
      state.product = null;
      state.apiDataLoaded = false;
      state.productResponse = false;
    } else {
      const locale = value.currentLocale;
      const data = {
        ...value.data,
        overlays: value.data.overlays.map(normalizeOverlay(locale)),
      };
      state.productResponse = value.data;
      state.product = new ProductI(data, locale);
      state.apiDataLoaded = true;
      if (state.isProductOverlayVisible === true) {
        return;
      }
      state.isProductOverlayVisible = true;
    }
  },

  OPEN_PRODUCT_OVERLAY(state, config) {
    if (state.isProductOverlayVisible === true) {
      return;
    }

    state.baseOverlayParams.type = config.type;

    state.productOverlayParams = {
      sectionName: config.sectionName,
      groupId: config.groupId,
      productId: config.productId,
    };

    if (state.isProductOverlayVisible === true) {
      return;
    }
    const delay = config.delayOpen || 0;
    setTimeout(() => {
      state.isProductOverlayVisible = true;
    }, delay);
  },

  HIDE_PRODUCT_OVERLAY(state) {
    if (state.isProductOverlayVisible === false) {
      return;
    }
    state.isProductOverlayVisible = false;
  },
  SHOW_GLOBAL_PRESENCE_OVERLAY(state) {
    if (state.isPromiseGlobalPresenceOverlayVisible === true) {
      return;
    }
    state.isPromiseGlobalPresenceOverlayVisible = true;
  },
  HIDE_GLOBAL_PRESENCE_OVERLAY(state) {
    if (state.isPromiseGlobalPresenceOverlayVisible === false) {
      return;
    }
    state.isPromiseGlobalPresenceOverlayVisible = false;
  },

  HIDE_OVERLAYS() {},
  CLEAR_VALUES(state) {
    state.baseOverlayParams.type = null;
    (state.baseOverlayParams.appPanelState = PanelOpenState.CLOSED),
      (state.baseOverlayParams.mainHeaderTitle = ""),
      (state.baseOverlayParams.childHeaderTitle = null),
      (state.baseOverlayParams.panelHeight = 60),
      (state.baseOverlayParams.panelHeightResized = 0),
      (state.baseOverlayParams.activeOverlayIndex = 0);

    state.apiDataLoaded = false;
  },
  productOverlayAction(state, action) {
    state.productOverlayAction = action;
  },
  setProductOverlayFirstTimeOpen(state, value) {
    state.productOverlayFirstTimeOpen = value;
  },
};
// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  baseOverlayParams: (state) => {
    return state.baseOverlayParams;
  },
  productOverlayParams: (state) => {
    return state.productOverlayParams;
  },
  product: (state) => {
    return state.product;
  },
  global_presence_group: (state) => {
    return state.global_presence_group;
  },
  global_presence: (state) => {
    return state.global_presence;
  },
  global_presense_loaded: (state) => {
    return state.global_presense_loaded;
  },
  currentProduct: (state) => {
    return state.product;
  },
  apiDataLoaded: (state) => {
    return state.apiDataLoaded;
  },

  isProductOverlayVisible: (state) => {
    return state.isProductOverlayVisible;
  },
  isPromiseGlobalPresenceOverlayVisible: (state) => {
    return state.isPromiseGlobalPresenceOverlayVisible;
  },

  productOverlayPanelWidth: (state) => {
    return state.productOverlayPanelWidth;
  },
  productOverlayAction: (state) => {
    return state.productOverlayAction;
  },
  settingsAction: (state) => {
    return state.settingsAction;
  },
  productOverlayFirstTimeOpen: (state) => {
    return state.productOverlayFirstTimeOpen;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};

function normalizeOverlay(locale) {
  return function (overlay) {
    return {
      ...overlay,
      _items: overlay._items.map((item) => {
        if (item.type === "BUTTON") {
          return {
            type: "BUTTON",
            copy: translate(item.value, locale),
            url: translate(item.link, locale),
          };
        }

        return item;
      }),
    };
  };
}

function translate(value, locale) {
  if (Array.isArray(value)) {
    return value.find((item) => item.locale == locale)?.value ?? null;
  } else if (typeof value === "string") {
    return value;
  }

  return null;
}
