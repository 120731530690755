<template>
  <div
    v-wave
    class="p-0 d-flex align-items-center"
    :class="{
      deskHighlightHeight: !isMobile,
      mobileHighlightHeight: isMobile,
    }"
    id="_overlay_label"
    @click="$emit('click')"
  >
    <div
      class="highlighticon"
      :class="{ highlighticonDesk: !isMobile, highlighticonMobile: isMobile }"
    >
      <component v-if="icon" v-bind:is="icon" />
    </div>
    <div
      class="_overlay_label_copy"
      :class="{
        highlightLabelDesk: !isMobile,
        highlightLabelMobile: isMobile,
      }"
      v-html="label"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HighlightButton",
  emits: ["click"],
  props: ["label", "icon"],
  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style scoped>
#_overlay_label {
  /* margin-top:10px; */
  background-color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  transition: background-color 0.45s ease-in-out;
}
.highlighticonDesk {
  margin-left: 14px;
  /*  margin-top:12px; */
  width: 46px;
  height: 46px;
}
.highlighticonMobile {
  margin-left: 14px;
  /*  margin-top:18px; */
  width: 50px;
  height: 50px;
}
#_overlay_label:hover {
  background-color: rgba(255, 255, 255, 0.65);
  transition: background-color 0.45s ease-in-out;
}
/*  background: linear-gradient(266.64deg, #5A9FD9 0%, #FCFB96 100%); */
._overlay_label_copy {
  font-family: "Univers LT Bold";
  color: #636363;
  margin-left: 15px;
  margin-right: 15px;
}
</style>
